import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/store'

import Home from '../views/Home.vue'
const Login = () => import('../views/settings/Login')
const Logout = () => import('../views/settings/Logout')
const ModifyPassword = () => import('../views/settings/ModifyPassword')
const ProfileEdition = () => import('../views/settings/ProfileEdition')

const CrawlpagesFromTags = () => import ('../views/crawlpage/CrawlpagesFromTags')

const Documentation = () => import('../views/documentation/Documentation')

const DomainList = () => import('../views/domain/DomainList')
const DomainCreation = () => import('../views/domain/DomainCreation')
const DomainModification = () => import('../views/domain/DomainModification')

const FolderPageList = () => import('../views/folder/FolderPageList')

const PageCrawlingTimeList = () => import ('../views/page/PageCrawlingTimeList')

const RequeteCreation = () => import('../views/requete/RequeteCreation')
const RequeteList = () => import('../views/requete/RequeteList')

const SearchCrawlpage = () => import('../views/crawlpage/SearchCrawlpage')

const TagCreation = () => import('../views/tag/TagCreation')
const TagList = () => import('../views/tag/TagList')

const TasksList = () => import('../views/task/TasksList')

const UserCreation = () => import('../views/user/UserCreation')
const UserList = () => import('../views/user/UserList')

Vue.use(VueRouter)

const ifNotAuthenticated = (to, from, next) => {
  if (!store.getters.isAuthenticated) {
    next()
    return
  }
  next('/')
}

const ifAuthenticated = (to, from, next) => {
  if (store.getters.isAuthenticated) {
    next()
    return
  }
  next('/login')
}



const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/temps-de-crawls',
    name: 'PageCrawlingTimeList',
    component: PageCrawlingTimeList,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/crawlpages-from-tags',
    name: 'CrawlpagesFromTags',
    component: CrawlpagesFromTags,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    beforeEnter: ifNotAuthenticated
  },
  {
    path: '/logout',
    name: 'Logout',
    component: Logout,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/settings/modify-password',
    name: 'ModifyPassword',
    component: ModifyPassword,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/settings/profile-edition',
    name: 'ProfileEdition',
    component: ProfileEdition,
    beforeEnter: ifAuthenticated
  },

  {
    path: '/crawlpage/recherche',
    name: 'SearchCrawlpage',
    component: SearchCrawlpage,
    beforeEnter: ifAuthenticated
  },

  {
    path: '/documentation',
    name: 'Documentation',
    component: Documentation,
    beforeEnter: ifAuthenticated
  },

  {
    path: '/domains',
    name: 'DomainList',
    component: DomainList,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/domains/creation',
    name: 'DomainCreation',
    component: DomainCreation,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/domains/:id',
    name: 'DomainModification',
    component: DomainModification,
    beforeEnter: ifAuthenticated
  },

  {
    path: '/folders-pages',
    name: 'FolderPageList',
    component: FolderPageList,
    beforeEnter: ifAuthenticated
  },

  {
    path: '/requetes',
    name: 'RequeteList',
    component: RequeteList,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/requetes/creation',
    name: 'RequeteCreation',
    component: RequeteCreation,
    beforeEnter: ifAuthenticated
  },

  {
    path: '/tags',
    name: 'TagList',
    component: TagList,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/tags/creation',
    name: 'TagCreation',
    component: TagCreation,
    beforeEnter: ifAuthenticated
  },


  {
    path: '/tasks',
    name: 'TasksList',
    component: TasksList,
    beforeEnter: ifAuthenticated
  },

  {
    path: '/users',
    name: 'UserList',
    component: UserList,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/users/creation',
    name: 'UserCreation',
    component: UserCreation,
    beforeEnter: ifAuthenticated
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
