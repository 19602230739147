<template>
  <div class="home">

    <!-- ERROR MODAL -->
      <b-modal ref="globalAPILoadingErrorModal" id="globalAPILoadingErrorModal"  hide-footer title="Une erreur s'est produite">
        <div class="text-center">
          <p>Une erreur s'est produite, veuillez actualiser la page et réessayer</p>
          <b-button class="mt-3" variant="outline-dark" @click="$bvModal.hide('globalAPILoadingErrorModal')">Fermer</b-button>
        </div>
      </b-modal>

    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      color="#F9BF3B">
    </loading>

    <formTitleComponent />
      <div class="container-fuild">
        <h1 class="form_div_title text-center">Résultats</h1>
        <div class="form_div">
          <div class="form_content">

            <div class="row row-height">

              <div :class="!isTreeOpen ? 'col-sm-1 col-2 text-center' : 'd-none'">
                <b-button  @click="isTreeOpen = !isTreeOpen" variant="light">
                  <v-icon name="bars" />
                </b-button>
              </div>

              <div :class="isTreeOpen ? 'col-sm-4 col-6 separate-scroll' : 'd-none separate-scroll'">
                <div class="row">
                  <div class="col-12 text-right">
                    <b-button class="pull-right" @click="isTreeOpen = !isTreeOpen" variant="light">
                      <v-icon name="bars" />
                    </b-button>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <resultatsFolderTreeViewComponent
                      v-for="rootFolder in rootFolders"
                      v-bind:key="rootFolder.id"
                      :depth="0"
                      :node="rootFolder"
                      :token="token"
                      v-on:choose-page="choosePage($event)"
                      v-on:choose-folder="chooseFolder($event)"
                    >
                    </resultatsFolderTreeViewComponent>
                    <resultatsPageTreeViewComponent
                      v-for="rootPage in rootPages"
                      v-bind:key="rootPage.id"
                      :depth="0"
                      :node="rootPage"
                      :token="token"
                      v-on:choose-page="choosePage($event)"
                    >
                    </resultatsPageTreeViewComponent>
                  </div>
                </div>
              </div>

              <div v-if="chosenPage" :class="isTreeOpen ? 'col-sm-8 col-6 separate-scroll' : 'col-sm-11 col-10 separate-scroll'">
                <pageProfileComponent
                  @back-to-folder="chooseFolder($event)"
                  :pageId="chosenPage.id"
                  :token="token"
                  :tags="tags"
                  />
              </div>

              <div v-else-if="chosenFolder" :class="isTreeOpen ? 'col-sm-8 col-6 separate-scroll' : 'col-sm-11 col-10 separate-scroll'">
                <folderProfileComponent
                  @choose-page="choosePage($event)"
                  :folderId="chosenFolder.id"
                  :token="token"
                  :tags="tags"
                  />
              </div>
            </div>

          </div>
        </div>
    </div>


  </div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

import { APIFolder } from '@/api/APIFolder'
import { APIPage } from '@/api/APIPage'
import { APITag } from '@/api/APITag'
import formTitleComponent from '@/components/formTitleComponent'
import resultatsFolderTreeViewComponent from '@/components/folder/resultatsFolderTreeViewComponent'
import resultatsPageTreeViewComponent from '@/components/page/resultatsPageTreeViewComponent'
import folderProfileComponent from '@/components/folder/folderProfileComponent'
import pageProfileComponent from '@/components/page/pageProfileComponent'

const apiFolder = new APIFolder()
const apiPage = new APIPage()
const apiTag = new APITag()

export default {
  name: 'Home',
  components: {
    formTitleComponent,
    Loading,
    resultatsFolderTreeViewComponent,
    resultatsPageTreeViewComponent,
    folderProfileComponent,
    pageProfileComponent
  },
  data: function () {
    return {
      rootFolders: [],
      isRootFoldersLoading: false,

      rootPages: [],
      isRootPagesLoading: false,

      chosenFolder: null,
      chosenPage: null,

      isTreeOpen: true,

      tags: [],
      isTagsLoading: false
    }
  },
  computed: {
    token () {
      return this.$store.state.auth.token
    },
    isLoading () {
      if (this.isRootFoldersLoading || this.isRootPagesLoading || this.isTagsLoading) {
        return true
      }
      return false
    },
  },
  created: function() {
    this.getAllRootFolders()
    this.getAllRootPages()
    this.getAllTags()
  },
  methods: {
    // ------------- GETTERS ------------------
    getAllRootFolders () {
      this.isRootFoldersLoading = true
      apiFolder.getAllRootFolders(this.token)
      .then((result) => {
        this.rootFolders = result.data
      })
      .catch((error) => {
        this.$refs.globalAPILoadingErrorModal.show()
        throw new Error('FolderPageList getAllRootFolders API Error : ' + String(error))
      })
      .finally(() => {
        this.isRootFoldersLoading = false
      })
    },
    getAllRootPages () {
      this.isRootPagesLoading = true
      apiPage.getAllRootPages(this.token)
      .then((result) => {
        this.rootPages = result.data
      })
      .catch((error) => {
        this.$refs.globalAPILoadingErrorModal.show()
        throw new Error('FolderPageList getAllRootPages API Error : ' + String(error))
      })
      .finally(() => {
        this.isRootPagesLoading = false
      })
    },

    getAllTags () {
      this.isTagsLoading = true
      apiTag.getAllTags(this.token)
      .then((result) => {
        this.tags = result.data
      })
      .catch((error) => {
        this.$refs.globalAPILoadingErrorModal.show()
        throw new Error('TagList getAllTags API Error : ' + String(error))
      })
      .finally(() => {
        this.isTagsLoading = false
      })
    },

    chooseFolder (value) {
      console.log(value)
      if (value['parent'] == null) {
        return null
      }
      this.chosenPage = null
      this.chosenFolder = value
    },

    choosePage (value) {
      this.chosenFolder = null
      this.chosenPage = value
    }
  }
}
</script>
<style>
.row-height {
  height: 100vh
}
.separate-scroll {
  height: 100%;
  overflow-y: scroll;
}

</style>
