<template>
  <div id="app">
    <b-navbar class="base_navbar navbar-dark bg-dark"  toggleable="md">
      <b-navbar-toggle target="nav_collapse"></b-navbar-toggle>

      <b-navbar-brand id="brand_logo" :to="{ path: '/' }">
          DECIDCRAWL
      </b-navbar-brand>

      <b-collapse is-nav id="nav_collapse" >
        <b-navbar-nav v-if="isAuthenticated" class="ml-auto">
          <b-nav-item class="navbar_items" to="/"> Résultats </b-nav-item>
          <b-nav-item class="navbar_items" to="/crawlpages-from-tags"> Tags </b-nav-item>
          <b-nav-item class="navbar_items" to="/tasks"> Crawls </b-nav-item>
          <b-nav-item class="navbar_items" to="/temps-de-crawls"> Temps de crawls </b-nav-item>
          <b-nav-item class="navbar_items" to="/crawlpage/recherche"> Recherche </b-nav-item>
          <b-nav-item class="navbar_items" to="/documentation"> Documentation </b-nav-item>
          <b-nav-item-dropdown  class="navbar_items" text="Paramétrage" right>
            <b-dropdown-item class="navbar_dropdown_items" to="/users">Paramétrage des comptes</b-dropdown-item>
            <b-dropdown-item class="navbar_dropdown_items" to="/domains">Paramétrage des domaines</b-dropdown-item>
            <b-dropdown-item class="navbar_dropdown_items" to="/folders-pages">Paramétrage des pages et dossiers</b-dropdown-item>
            <b-dropdown-item class="navbar_dropdown_items" to="/requetes">Paramétrage des requêtes</b-dropdown-item>
            <b-dropdown-item class="navbar_dropdown_items" to="/tags">Paramétrage des tags</b-dropdown-item>
          </b-nav-item-dropdown>
          <b-nav-item-dropdown  class="navbar_items" text="Profil" right>
            <b-dropdown-item class="navbar_dropdown_items" to="/settings/profile-edition">Editer votre profil</b-dropdown-item>
            <b-dropdown-item class="navbar_dropdown_items" to="/settings/modify-password">Modifier votre mot de passe</b-dropdown-item>
          </b-nav-item-dropdown>
          <b-nav-item class="navbar_items" to="/logout">Se déconnecter</b-nav-item>
        </b-navbar-nav>

        <b-navbar-nav v-else class="ml-auto">
          <b-nav-item class="navbar_items" to="/login">Se connecter</b-nav-item>
        </b-navbar-nav>

      </b-collapse>
    </b-navbar>
    <router-view/>
  </div>
</template>

<script>
export default {
  name: 'App',
  computed: {
    isAuthenticated () {
      return this.$store.getters.isAuthenticated
    },
    token () {
      return this.$store.getters.TOKEN
    }
  },
}
</script>

<style>

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  src: url("./assets/fonts/open-sans/OpenSans-Regular.ttf");
  font-display: swap;
}

@font-face {
  font-family: "Dosis";
  font-style: normal;
  src: url("./assets/fonts/dosis/Dosis-Medium.ttf");
  font-display: swap;
}


html, body { overflow-x: hidden; }


body {
  font-family: Open Sans, sans-serif !important;
  padding-right: 0 !important;
}
.masthead {
  padding-top: 2%;
  padding-bottom: 2%;
}

.justify-text {
  text-align: justify;
}


#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* color: #2c3e50; */

}

.base_navbar {
  height: 80px;
}
#brand_logo {
  font-size: 1.8em;
  letter-spacing: 0.1em;
  font-family: Dosis, sans-serif !important;
}

#nav_collapse {
  z-index: 1001;
}

.navbar_items {
  font-weight: bold;
  font-size: 14px;
  margin-left: 10px;
}

.nav-link {
  color: rgba(255, 255, 255, 1) !important;
}


@media (min-width: 480px) {
  .base_navbar {
    height: 70px;
  }

}

@media (min-width: 1024px) {
  .base_navbar {
    height: 70px !important;
  }
  .navbar_items {
    font-size: 18px;
  }
}

div.mandatory-input label:after {
  content:"*";
  color:red;
}

.form_div {
  background: #FFFFFF;
  padding-left: 15px;
  padding-bottom: 20px;
  border-style: solid;
  border-color: #BDBDBD;
  border-width: 0.5px;
  margin-top: 15px;
}

.form_div_title{
  color: #000000;
  font-weight: bold;
  font-size: calc(1.1em + 0.6vw);
  margin-top: -90px;
}

.form_content {
  margin-top: 10px;
  padding-left: 15px;
  padding-right: 15px;
}

.form_content h2 {
  color: #454F59;
  margin-top:10px;
  font-weight: bold;
  font-size: calc(1.1em + 0.1vw);
}

.form_content h3 {
  font-weight: bold;
  color: #6E6E6E;
  margin-top:10px;
  font-size: calc(0.9em + 0.4vw);
}

.form-error {
  color: #B1060A;
  font-size: 0.8em;
  font-style: italic;
}
</style>
