<template>
  <div class="resultatsPageTreeViewComponent">
    <div class="tree-menu">
      <div class="label-wrapper cursor-class" >
        <div :style="indent">
          📝
          <span @click="choosePage" :class="node.is_bugged ? 'ml-2 text-danger' : 'ml-2'">
            {{ node.name }}
            <b-badge v-if="node.nb_crawledpage_detected > 0" pill variant="danger">{{node.nb_crawledpage_detected}}</b-badge>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { APIPage } from '@/api/APIPage'

const apiPage = new APIPage()

export default {
  name: 'resultatsPageTreeViewComponent',
  props: {
    depth: Number,
    node: Object,
    token: String
  },
  computed: {
    indent() {
      return { transform: `translate(${this.depth * 50}px)` }
    },
  },
  methods: {
    crawlPage () {
      apiPage.crawlPage(
        this.token, this.node.id)
      .then((result) => {
        console.log(result)
      })
      .catch((error) => {
        throw new Error('PageMovingComponent movePage API Error : ' + String(error))
      })
    },
    choosePage () {
      this.$emit('choose-page', this.node)
    }

  }
}
</script>

<style>
.cursor-class {
  cursor: pointer;
}
</style>
