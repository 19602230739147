<template>
  <div class="resultatsFolderTreeViewComponent">
    <div class="tree-menu">

      <!-- ERROR MODAL -->
      <b-modal ref="globalAPILoadingErrorModal" id="globalAPILoadingErrorModal"  hide-footer title="Une erreur s'est produite">
        <div class="text-center">
          <p>Une erreur s'est produite, veuillez actualiser la page et réessayer</p>
          <b-button class="mt-3" variant="outline-dark" @click="$bvModal.hide('globalAPILoadingErrorModal')">Fermer</b-button>
        </div>
      </b-modal>

      <loading
        :active.sync="isLoading"
        :can-cancel="false"
        :is-full-page="true"
        color="#F9BF3B">
      </loading>

      <div class="label-wrapper cursor-class">
        <div :style="indent" @click="toggleChildren">
          <span class="icon-folder-class" v-if="!showFolderChildren">📁</span>
          <span class="icon-folder-class" v-else>📂</span>
          <span class="ml-2">
            {{ node.name }}
            <b-badge pill variant="light">{{nbChildrenPages}}</b-badge>
            <b-badge v-if="nbCrawlPagesDetectedUnread > 0" pill variant="danger">{{nbCrawlPagesDetectedUnread}}</b-badge>
          </span>
        </div>
      </div>

      <div v-if="showFolderChildren">
        <resultatsFolderTreeViewComponent
          v-for="folder in childrenFolders"
          v-bind:key="folder.id"
          :depth="depth + 1"
          :node="folder"
          :token="token"
          v-on:choose-page="$emit('choose-page', $event)"
          v-on:choose-folder="$emit('choose-folder', $event)"
        >
        </resultatsFolderTreeViewComponent>
      </div>

      <div v-if="showPageChildren">
        <resultatsPageTreeViewComponent
          v-for="page in childrenPages"
          v-bind:key="page.id"
          :depth="depth + 1"
          :node="page"
          :token="token"
          v-on:choose-page="$emit('choose-page', $event)"
        >
        </resultatsPageTreeViewComponent>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

import resultatsPageTreeViewComponent from '@/components/page/resultatsPageTreeViewComponent'

import { APIFolder } from '@/api/APIFolder'
import { APIPage } from '@/api/APIPage'

const apiFolder = new APIFolder()
const apiPage = new APIPage()

export default {
  name: 'resultatsFolderTreeViewComponent',
  props: {
    depth: Number,
    node: Object,
    token: String
  },
  components: {
    Loading,
    resultatsPageTreeViewComponent
  },
  data() {
     return {
       showFolderChildren: false,
       showPageChildren: false,

       nbCrawlPagesDetectedUnread: 0,
       nbChildrenPages: 0,

       childrenFolders: [],
       isChildrenFoldersLoading: false,

       childrenPages: [],
       isChildrenPagesLoading: false,
     }
  },
  computed: {
    indent() {
      return { transform: `translate(${this.depth * 50}px)` }
    },
    isLoading () {
      if (this.isChildrenFoldersLoading || this.isChildrenPagesLoading) {
        return true
      }
      return false
    },
  },
  mounted: function() {
    this.getNumberCrawlPages()
  },
  methods: {
    toggleChildren() {
       if (this.showFolderChildren) {
         this.showFolderChildren = !this.showFolderChildren
       }
       else {
         this.$emit('choose-folder', this.node)
         this.getChildrenFolders()
       }
       if (this.showPageChildren) {
         this.showPageChildren = !this.showPageChildren
       }
       else {
         this.getChildrenPages()
       }
    },
    getChildrenFolders() {
      this.isChildrenFoldersLoading = true
      apiFolder.getChildrenFolders(this.token, this.node.id)
      .then((result) => {
        this.childrenFolders = result.data
        this.showFolderChildren = !this.showFolderChildren;
      })
      .catch((error) => {
        this.$refs.globalAPILoadingErrorModal.show()
        throw new Error('treeViewComponent getChildrenFolders API Error : ' + String(error))
      })
      .finally(() => {
        this.isChildrenFoldersLoading = false
      })
    },
    getChildrenPages() {
      this.isChildrenPagesLoading = true
      apiPage.getChildrenPages(this.token, this.node.id)
      .then((result) => {
        this.childrenPages = result.data
        this.showPageChildren = !this.showPageChildren;
      })
      .catch((error) => {
        this.$refs.globalAPILoadingErrorModal.show()
        throw new Error('treeViewComponent getChildrenFolders API Error : ' + String(error))
      })
      .finally(() => {
        this.isChildrenPagesLoading = false
      })
    },

    getNumberCrawlPages() {
      apiFolder.getFolderPagesStatistics(this.token, this.node.id)
      .then((result) => {
        this.nbCrawlPagesDetectedUnread = result.data['nb_crawled_pages']
        this.nbChildrenPages = result.data['nb_children_pages']
      })
      .catch((error) => {
        this.$refs.globalAPILoadingErrorModal.show()
        throw new Error('treeViewComponent getChildrenFolders API Error : ' + String(error))
      })
    },
  }
}
</script>

<style>
.cursor-class {
  cursor: pointer;
}

.icon-folder-class:hover {
  font-size: 1.2rem;
}

.title-folder-class:hover {
  font-weight: 600;
}
</style>
