import { USER_SUCCESS, USER_LOGOUT } from '../actions/user'

const state = {
  userFirstName: localStorage.getItem('decidcrawl-user-first_name') || '',
  userLastName: localStorage.getItem('decidcrawl-user-last_name') || '',
  userUsername: localStorage.getItem('decidcrawl-user-username') || '',
}

const getters = {
  getUserFirstName: state => state.userFirstName,
  getUserLastName: state => state.userLastName,
  getUserUsername: state => state.userUsername,

}

const actions = {
  [USER_LOGOUT]: ({commit}) => {
    commit(USER_LOGOUT)
  }
}

const mutations = {
  [USER_SUCCESS]: (state, resp) => {
    state.status = 'success'
    state.userFirstName = resp.first_name
    localStorage.setItem('decidcrawl-user-first_name', resp.first_name)
    state.userLastName = resp.last_name
    localStorage.setItem('decidcrawl-user-last_name', resp.last_name)
    state.userUsername = resp.username
    localStorage.setItem('decidcrawl-user-username', resp.username)

  },
  [USER_LOGOUT]: (state) => {
    state.userFirstName = ''
    localStorage.removeItem('decidcrawl-user-first_name')
    state.userLastName = ''
    localStorage.removeItem('decidcrawl-user-last_name')
    state.userUsername = ''
    localStorage.removeItem('decidcrawl-user-username')
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
