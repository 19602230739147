import axios from 'axios'
import { apiBaseUrl } from '@/variables/localVariables'
import store from '@/store/store'
import router from '@/router'

const axios_instance = axios.create({
  baseURL:apiBaseUrl,
});

var isRefreshing = false

//Add a response interceptor
axios_instance.interceptors.response.use(
  function (response) {
    store.dispatch('AUTH_INSPECT')
      .catch(() => {
        store.commit('AUTH_LOGOUT')
        router.push('/login')
      })
    return response
  },
  function (error) {
    const originalRequest = error.config;
    if (error.response.status === 401 && originalRequest.url === apiBaseUrl +'/auth/token/refresh') {
     router.push('/login');
     return Promise.reject(error);
    }

    if (error.response.status === 401 && !originalRequest._retry) {
      if (!isRefreshing) {
        isRefreshing = true
        originalRequest._retry = true
        const refreshToken = JSON.parse(store.state.auth.token).refresh

        return axios_instance.post(apiBaseUrl +'/auth/token/refresh', {"refresh": refreshToken})
         .then(res => {
           if (res.status === 200) {
             store.commit('AUTH_REFRESH', res.data)
             originalRequest.headers['Authorization'] = 'Bearer ' + res.data.access
             return axios_instance(originalRequest)
           }
           else {
             store.commit('AUTH_LOGOUT')
             router.push('/login')
           }
         })
         .catch(() => {
           store.commit('AUTH_LOGOUT')
           router.push('/login')
         })
         .finally(() => {
           isRefreshing = false
         })
     }
     originalRequest.headers['Authorization'] = 'Bearer ' + JSON.parse(store.state.auth.token).access
     return axios_instance(originalRequest)
   }
   return Promise.reject(error)
});

export class APIFolder {

  crawlFolder (token, id) {

    const url = apiBaseUrl + `/crawl-folder/` + String(id)

    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json'
    }

    return axios_instance.get(
      url,
      {
        'headers': headers,
      }
    )
  }

  createFolder (token, data) {
    const url = apiBaseUrl + `/create-folder`

    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json'
    }

    return axios_instance.post(
      url, data,
      {
        'headers': headers,
      }
    )
  }

  deleteFolder (token, id) {

    const url = apiBaseUrl + `/folder/` + String(id)

    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json'
    }

    return axios_instance.delete(
      url,
      {
        'headers': headers,
      }
    )
  }

  getAllRootFolders(token) {
    const url = apiBaseUrl + `/root-folders`
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.get(url, {'headers': headers,})
  }

  getChildrenFolders(token, id) {
    const url = apiBaseUrl + `/children-folders/` + String(id)
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.get(url, {'headers': headers,})
  }

  getFolder(token, id) {
    const url = apiBaseUrl + `/folder/` + String(id)
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.get(url, {'headers': headers,})
  }

  getFolderCrawlpageStatistics(token, id, startDate, endDate) {
    const url = `${apiBaseUrl}/crawlpages-statistics-from-folder/${id}/${startDate}/${endDate}`
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.get(url, {'headers': headers,})
  }

  getFolderPagesStatistics(token, id) {
    const url = apiBaseUrl + `/folder-pages-statistics/` + String(id)
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.get(url, {'headers': headers,})
  }

  getFolderPagesWithoutCrawlpages(token, id, startDate, endDate) {
    const url = `${apiBaseUrl}/folder/${String(id)}/pages/without-crawlpages/${startDate}/${endDate}`
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.get(url, {'headers': headers,})
  }

  modifyFolder (token, id, data) {

    const url = apiBaseUrl + `/folder/` + String(id)

    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json'
    }
    return axios_instance.put(
      url, data,
      {
        'headers': headers,
      }
    )
  }

  modifyNotesFolder (token, id, notes) {

    const calling_url = apiBaseUrl + `/modify-notes-folder/` + String(id)

    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json'
    }
    var data = {
      'notes': notes,
    }

    return axios_instance.put(
      calling_url, data,
      {
        'headers': headers,
      }
    )
  }

  moveFolder (token, id, parent) {

    const url = apiBaseUrl + `/move-folder/` + String(id)

    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json'
    }
    var data = {
    }

    if (parent) {
      data['parent'] = parent.id
    }

    return axios_instance.put(
      url, data,
      {
        'headers': headers,
      }
    )
  }

  searchFoldersByName(token, search) {
    const url = apiBaseUrl + `/search-folders/` + String(search)
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.get(url, {'headers': headers,})
  }
}
