import axios from 'axios'
import { apiBaseUrl } from '@/variables/localVariables'

export class AuthAPIService {
  getToken (user) {
    const url = apiBaseUrl + `/auth/token`
    return axios.post(url, user)
  }

  refreshToken (refreshToken) {
    const url = apiBaseUrl + `/auth/token/refresh`
    return axios.post(url, {'refresh': refreshToken})
  }
}
