export default {
  methods: {
    domainTypeRender(state) {
      switch (state) {
        case 'PHR':
          return 'Presse hebodmadaire régionale'
        case 'PQR':
          return 'Presse quotidienne régionale'
        case 'PPR':
          return 'Presse professionnelle'
      }
      return 'Erreur'
    },
    periodiciteRender(state) {
      switch (state) {
        case 0:
          return 'Aucun.'
        case 1:
          return 'Quot.'
        case 7:
          return 'Hebd.'
        case 30:
          return 'Mens.'
        case 90:
          return 'Trim.'
      }
      return 'Erreur'
    },
    minutesAndSeconds(totalSeconds) {
      // 👇️ get number of full minutes
      const minutes = Math.floor(totalSeconds / 60);

      // 👇️ get remainder of seconds
      const seconds = totalSeconds % 60;

      function padTo2Digits(num) {
        return num.toString().padStart(2, '0');
      }
      // ✅ format as MM:SS
      return `${padTo2Digits(minutes)} min ${padTo2Digits(parseFloat(seconds).toFixed(0))} s`;
    }
  }
}
