import axios from 'axios'
import { apiBaseUrl } from '@/variables/localVariables'
import store from '@/store/store'
import router from '@/router'

const axios_instance = axios.create({
  baseURL:apiBaseUrl,
});

var isRefreshing = false

//Add a response interceptor
axios_instance.interceptors.response.use(
  function (response) {
    store.dispatch('AUTH_INSPECT')
      .catch(() => {
        store.commit('AUTH_LOGOUT')
        router.push('/login')
      })
    return response
  },
  function (error) {
    const originalRequest = error.config;
    if (error.response.status === 401 && originalRequest.url === apiBaseUrl +'/auth/token/refresh') {
     router.push('/login');
     return Promise.reject(error);
    }

    if (error.response.status === 401 && !originalRequest._retry) {
      if (!isRefreshing) {
        isRefreshing = true
        originalRequest._retry = true
        const refreshToken = JSON.parse(store.state.auth.token).refresh

        return axios_instance.post(apiBaseUrl +'/auth/token/refresh', {"refresh": refreshToken})
         .then(res => {
           if (res.status === 200) {
             store.commit('AUTH_REFRESH', res.data)
             originalRequest.headers['Authorization'] = 'Bearer ' + res.data.access
             return axios_instance(originalRequest)
           }
           else {
             store.commit('AUTH_LOGOUT')
             router.push('/login')
           }
         })
         .catch(() => {
           store.commit('AUTH_LOGOUT')
           router.push('/login')
         })
         .finally(() => {
           isRefreshing = false
         })
     }
     originalRequest.headers['Authorization'] = 'Bearer ' + JSON.parse(store.state.auth.token).access
     return axios_instance(originalRequest)
   }
   return Promise.reject(error)
});

export class APIPage {

  createPage (token, folder, force_selenium, force_selenium_for_filles, is_all_domains, is_modif_annuelle, name, notes, profondeur,
    url, url_for_file_root, urls_exclude, urls_include, xpaths_clicked, zone_analysed, zone_excluded_from_analysis, zone_crawled) {

    const calling_url = apiBaseUrl + `/create-page`

    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json'
    }
    var data = {
      'folder': folder.id,
      'force_selenium': force_selenium,
      'force_selenium_for_filles': force_selenium_for_filles,
      'is_all_domains': is_all_domains,
      'is_modif_annuelle': is_modif_annuelle,
      'name': name,
      'notes': notes,
      'profondeur': profondeur,
      'url': url,
      'url_for_file_root': url_for_file_root,
      'urls_include': urls_include,
      'urls_exclude': urls_exclude,
      'xpaths_clicked': xpaths_clicked,
      'zone_analysed': zone_analysed,
      "zone_excluded_from_analysis": zone_excluded_from_analysis,
      'zone_crawled': zone_crawled,
    }

    return axios_instance.post(
      calling_url, data,
      {
        'headers': headers,
      }
    )
  }

  crawlPage (token, id) {

    const url = apiBaseUrl + `/crawl-page/` + String(id)

    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json'
    }

    return axios_instance.get(
      url,
      {
        'headers': headers,
      }
    )
  }


  deletePage (token, id) {

    const url = apiBaseUrl + `/page/` + String(id)

    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json'
    }

    return axios_instance.delete(
      url,
      {
        'headers': headers,
      }
    )
  }

  deletePages (token, ids) {
    let url = `${apiBaseUrl}/delete-pages?`
    ids.forEach((id) => {
      url = `${url}ids=${id}&`
    })

    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json'
    }

    return axios_instance.delete(
      url,
      {
        'headers': headers,
      }
    )
  }


  getAllPages(token) {
    const url = `${apiBaseUrl}/pages/`
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.get(url, {'headers': headers,})
  }

  getAllRootPages(token) {
    const url = apiBaseUrl + `/root-pages`
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.get(url, {'headers': headers,})
  }

  getChildrenPages(token, id) {
    const url = apiBaseUrl + `/children-pages/` + String(id)
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.get(url, {'headers': headers,})
  }

  getNbPageByUrl(token, pageUrl, pageId) {
    const url = apiBaseUrl + `/nb-pages-by-url`
    var params = {
      url: pageUrl,
      pageId: pageId
    }
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.get(url, {'headers': headers, params: params})
  }

  getPage(token, id) {
    const url = apiBaseUrl + `/page/` + String(id)
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.get(url, {'headers': headers,})
  }

  getPagePath(token, search) {
    const url = apiBaseUrl + `/page-path`
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }

    var params = {
      'search': search
    }
    return axios_instance.get(url, {'headers': headers, params: params})
  }

  getPageStatistics(token, id, startDate, endDate) {
    const url = `${apiBaseUrl}/page/${id}/statistics?startDate=${startDate}&endDate=${endDate}`
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.get(url, {'headers': headers,})
  }

  modifyPage (token, id, force_selenium, force_selenium_for_filles, is_all_domains, is_modif_annuelle, name, notes, profondeur,
    url, url_for_file_root, urls_exclude, urls_include, xpaths_clicked, zone_analysed, zone_excluded_from_analysis, zone_crawled) {

    const calling_url = apiBaseUrl + `/page/` + String(id)

    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json'
    }
    var data = {
      'force_selenium': force_selenium,
      'force_selenium_for_filles': force_selenium_for_filles,
      'is_all_domains': is_all_domains,
      'is_modif_annuelle': is_modif_annuelle,
      'name': name,
      'notes': notes,
      'profondeur': profondeur,
      'url': url,
      'url_for_file_root': url_for_file_root,
      'urls_include': urls_include,
      'urls_exclude': urls_exclude,
      'xpaths_clicked': xpaths_clicked,
      'zone_analysed': zone_analysed,
      "zone_excluded_from_analysis": zone_excluded_from_analysis,
      'zone_crawled': zone_crawled,
    }

    return axios_instance.put(
      calling_url, data,
      {
        'headers': headers,
      }
    )
  }

  modifyNotesPage (token, id, notes) {

    const calling_url = apiBaseUrl + `/modify-notes-page/` + String(id)

    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json'
    }
    var data = {
      'notes': notes,
    }

    return axios_instance.put(
      calling_url, data,
      {
        'headers': headers,
      }
    )
  }

  movePage (token, id, folder) {

    const url = apiBaseUrl + `/move-page/` + String(id)

    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json'
    }
    var data = {}
    if (folder) {
      data['folder'] = folder.id
    }

    return axios_instance.put(
      url, data,
      {
        'headers': headers,
      }
    )
  }

  moveMultiplePages (token, pages_pk, folder) {

    const url = apiBaseUrl + `/move-pages`

    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json'
    }
    var data = {
      'pages': pages_pk,
    }
    if (folder) {
      data['folder'] = folder.id
    }

    return axios_instance.put(
      url, data,
      {
        'headers': headers,
      }
    )
  }
}
