<template>
  <div class="modifyPageNotesComponent">
      <b-modal ref="notesModifiedModal" id="notesModifiedModal"  hide-footer title="Notes modifiées">
        <div class="text-center">
          <p>Vos notes ont bien été modifiées</p>
          <b-button class="mt-3" variant="outline-dark" @click="$bvModal.hide('notesModifiedModal')">Fermer</b-button>
        </div>
      </b-modal>

      <loading
        :active.sync="isLoading"
        :can-cancel="false"
        :is-full-page="true"
        color="#F9BF3B">
      </loading>

      <b-input-group class="mt-3">
       <template #append>
         <b-button variant="light" @click="modifyNotesPage"><v-icon name="save" /></b-button>
       </template>
       <b-form-textarea
         v-model="modifyNotes" type="text"
         placeholder="Notes"
         id="modify-page-notes-input"
         rows="2"
         maxLength="500"
         @input="$v.modifyNotes.$touch()"
         :state="$v.modifyNotes.$dirty ? !$v.modifyNotes.$error : null">
       </b-form-textarea>
      </b-input-group>
      
  </div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import { validationMixin } from 'vuelidate'
import { minLength, maxLength } from 'vuelidate/lib/validators'

import { APIPage } from '@/api/APIPage'

const apiPage = new APIPage()

export default {
  name: 'modifyPageNotesComponent',
  props: {
    pageId: Number,
    token: String,
    notes: String
  },
  components: {
    Loading,
  },
  mixins: [
    validationMixin
  ],
  data() {
     return {
       modifyNotes: this.notes,
       isModifyingNotes: false,
     }
  },
  watch: {
    notes: function(newNotes) {
      this.modifyNotes = newNotes
    }
  },

  validations: {
    modifyNotes: {
      minLength: minLength(2),
      maxLength: maxLength(500),
    },
  },

  computed: {
    isLoading () {
      if (this.isModifyingNotes ) {
        return true
      }
      return false
    },
  },


  methods: {
    modifyNotesPage () {
      this.isModifyingNotes = true
      apiPage.modifyNotesPage(this.token, this.pageId, this.modifyNotes)
      .then(() => {
        this.$refs.notesModifiedModal.show()
      })
      .catch((error) => {
        this.$refs.globalAPILoadingErrorModal.show()
        throw new Error('modifyPageNotesComponent markAllCrawlPagesAsRead API Error : ' + String(error))
      })
      .finally(() => {
        this.isModifyingNotes = false
      })
    },

  }
}
</script>
