import axios from 'axios'
import { apiBaseUrl } from '@/variables/localVariables'
import store from '@/store/store'
import router from '@/router'

const axios_instance = axios.create({
  baseURL:apiBaseUrl,
});

var isRefreshing = false

//Add a response interceptor
axios_instance.interceptors.response.use(
  function (response) {
    store.dispatch('AUTH_INSPECT')
      .catch(() => {
        store.commit('AUTH_LOGOUT')
        router.push('/login')
      })
    return response
  },
  function (error) {
    const originalRequest = error.config;
    if (error.response.status === 401 && originalRequest.url === apiBaseUrl +'/auth/token/refresh') {
      router.push('/login');
      return Promise.reject(error);
    }

    if (error.response.status === 401 && !originalRequest._retry) {
      if (!isRefreshing) {
        isRefreshing = true
        originalRequest._retry = true
        const refreshToken = JSON.parse(store.state.auth.token).refresh

        return axios_instance.post(apiBaseUrl +'/auth/token/refresh', {"refresh": refreshToken})
         .then(res => {
           if (res.status === 200) {
             store.commit('AUTH_REFRESH', res.data)
             originalRequest.headers['Authorization'] = 'Bearer ' + res.data.access
             return axios_instance(originalRequest)
           }
           else {
             store.commit('AUTH_LOGOUT')
             router.push('/login')
           }
         })
         .catch(() => {
           store.commit('AUTH_LOGOUT')
           router.push('/login')
         })
         .finally(() => {
           isRefreshing = false
         })
     }
     originalRequest.headers['Authorization'] = 'Bearer ' + JSON.parse(store.state.auth.token).access
     return axios_instance(originalRequest)
   }
   return Promise.reject(error)
});

export class APICrawlPage {

  getAllChildrenCrawlPagesDetectedUnread(token, id, start, end) {
    const url = apiBaseUrl + `/children-crawlpages-unread-detected/` + String(id) + '/' + String(start) + '/' + String(end)
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.get(url, {'headers': headers,})
  }

  getAllChildrenCrawlPagesDetectedRead(token, id, start, end) {
    const url = apiBaseUrl + `/children-crawlpages-read-detected/` + String(id) + '/' + String(start) + '/' + String(end)
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.get(url, {'headers': headers,})
  }

  getAllChildrenCrawlPagesUndetected(token, id, start, end) {
    const url = apiBaseUrl + `/children-crawlpages-undetected/` + String(id) + '/' + String(start) + '/' + String(end)
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.get(url, {'headers': headers,})
  }

  // ------------------ FOLDER ----------------------
  getAllCrawlPagesFromFolder(token, folder_id, start, end, is_detected, is_read) {
    const url = apiBaseUrl + `/crawlpages-from-folder/` + String(folder_id) + '/' + String(start) + '/' + String(end)
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    var params = {
      'is-detected': is_detected
    }
    if (is_read != null) {
      params['is-read'] = is_read
    }
    return axios_instance.get(url, {'headers': headers, params: params})
  }


  // ------------------- TAG -------------------------

  getAllCrawlpagesByTag (token, url) {

    /* Be careful in this page because a URL with the page is needed !!!
    Default URL is : crawlpages-from-tag/
    */

    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.get(url, {'headers': headers,})
  }

  markAllCrawlPagesAsReadFromFolder(token, folder_pk, start, end) {
    const url = apiBaseUrl + `/mark-all-crawlpages-as-read-from-folder/` + String(folder_pk) + '/' + String(start) + '/' + String(end)
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.put(url, {}, {'headers': headers,})
  }

  markAllCrawlPagesAsReadFromPage(token, page_pk, start, end) {
    const url = apiBaseUrl + `/mark-all-crawlpages-as-read-from-page/` + String(page_pk) + '/' + String(start) + '/' + String(end)
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.put(url, {}, {'headers': headers,})
  }

  markCrawlPageAsRead(token, pk) {
    const url = apiBaseUrl + `/mark-crawlpage-as-read/` + String(pk)
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.put(url, {}, {'headers': headers,})
  }

  tagCrawlPage(token, pk, tag_ids) {
    const url = apiBaseUrl + `/tag-crawlpage/` + String(pk)
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    var data = {
      'tags': tag_ids
    }
    return axios_instance.put(url, data, {'headers': headers,})
  }

  searchCrawlpage(token, crawlpageUrl) {
    const url = `${apiBaseUrl}/crawlpages?url=${crawlpageUrl}`
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.get(url, {'headers': headers,})
  }

  removeTagCrawlPage(token, pk, tag_ids) {
    const url = apiBaseUrl + `/remove-tag-crawlpage/` + String(pk)
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    var data = {
      'tags': tag_ids
    }
    return axios_instance.put(url, data, {'headers': headers,})
  }

}
