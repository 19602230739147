import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/store'
import Vuex from 'vuex'

import {
  CardPlugin, BadgePlugin, LayoutPlugin, ModalPlugin, DropdownPlugin,
  NavPlugin, NavbarPlugin, ButtonPlugin,
  FormPlugin, FormCheckboxPlugin, FormGroupPlugin,
  FormInputPlugin, InputGroupPlugin, FormSelectPlugin,
  FormFilePlugin, FormTextareaPlugin, SpinnerPlugin,
  TablePlugin, TabsPlugin, ImagePlugin, FormRadioPlugin,
  FormDatepickerPlugin, PopoverPlugin, ToastPlugin, TooltipPlugin
} from 'bootstrap-vue'

import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import 'vue-awesome/icons/arrow-left'
import 'vue-awesome/icons/bars'
import 'vue-awesome/icons/check'
import 'vue-awesome/icons/download'
import 'vue-awesome/icons/edit'
import 'vue-awesome/icons/external-link-alt'
import 'vue-awesome/icons/info'
import 'vue-awesome/icons/plus'
import 'vue-awesome/icons/pen'
import 'vue-awesome/icons/pen-square'
import 'vue-awesome/icons/question'
import 'vue-awesome/icons/save'
import 'vue-awesome/icons/search'
import 'vue-awesome/icons/sync'
import 'vue-awesome/icons/times-circle'
import 'vue-awesome/icons/tv'
import 'vue-awesome/icons/trash-alt'

import VueDayjs from 'vue-dayjs-plugin'
import 'dayjs/locale/fr'

import Multiselect from 'vue-multiselect'
import "vue-multiselect/dist/vue-multiselect.min.css"

import Icon from 'vue-awesome/components/Icon'

import browserDetect from "vue-browser-detect-plugin";

import JsonExcel from "vue-json-excel";

Vue.config.productionTip = false

Vue.use(Vuex)

Vue.use(CardPlugin)
Vue.use(BadgePlugin)
Vue.use(LayoutPlugin)
Vue.use(ModalPlugin)
Vue.use(DropdownPlugin)
Vue.use(NavPlugin)
Vue.use(NavbarPlugin)
Vue.use(ButtonPlugin)
Vue.use(FormPlugin)
Vue.use(FormCheckboxPlugin)
Vue.use(FormGroupPlugin)
Vue.use(FormInputPlugin)
Vue.use(InputGroupPlugin)
Vue.use(SpinnerPlugin)
Vue.use(FormFilePlugin)
Vue.use(FormTextareaPlugin)
Vue.use(TablePlugin)
Vue.use(TabsPlugin)
Vue.use(FormSelectPlugin)
Vue.use(ImagePlugin)
Vue.use(FormRadioPlugin)
Vue.use(FormDatepickerPlugin)
Vue.use(PopoverPlugin)
Vue.use(ToastPlugin)
Vue.use(TooltipPlugin)

Vue.use(VueDayjs)

Vue.use(browserDetect)

Vue.component('v-icon', Icon)
Vue.component('vue-multiselect-input', Multiselect)
Vue.component("downloadExcel", JsonExcel);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
