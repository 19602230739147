<template>
  <div class="crawlpageTaggingComponent">
    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      color="#F9BF3B">
    </loading>
    <span v-for="tag in crawlpage.tags" v-bind:key="tag.id">
      <b-badge @click="removeTagCrawlPage(tag)" style="cursor: pointer;" pill variant="info" class="ml-2" size="sm" >
        {{tag.name}}
      </b-badge>
    </span>

    <span v-for="tagLeft in tagsLeftToAdd" v-bind:key="tagLeft.id">
      <b-badge @click="tagCrawlPage(tagLeft)" style="cursor: pointer;" pill variant="light" class="ml-2" size="sm" >
        {{tagLeft.name}}
      </b-badge>
    </span>

  </div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

import { APICrawlPage } from '@/api/APICrawlPage'

const apiCrawlPage = new APICrawlPage()


export default {
  name: 'crawlpageTaggingComponent',
  props: {
    crawlpage: Object,
    token: String,
    tags: Array
  },
  components: {
    Loading,
  },

  data() {
     return {
       isTaggingCrawlpage: false,
     }
  },

  computed: {
    isLoading () {
      if (this.isTaggingCrawlpage) {
        return true
      }
      return false
    },

    tagsLeftToAdd () {
      var toRemove = this.crawlpage.tags
      return this.tags.filter( function( item ){
        return toRemove.find( function( item2 ){
          return item.id == item2.id;
        }) == undefined;
      });
    },
  },

  methods: {
    tagCrawlPage (tag) {
      this.isTaggingCrawlpage = true
      apiCrawlPage.tagCrawlPage(this.token, this.crawlpage.id, [tag.id])
      .then(() => {
        if (this.crawlpage.tags.indexOf(tag) < 0) {
          this.crawlpage.tags.push(tag)
        }
      })
      .catch((error) => {
        this.$refs.globalAPILoadingErrorModal.show()
        throw new Error('crawlpageAddTagsComponent tagCrawlPage API Error : ' + String(error))
      })
      .finally(() => {
        this.isTaggingCrawlpage = false
      })
    },

    removeTagCrawlPage (tag) {
      this.isTaggingCrawlpage = true
      apiCrawlPage.removeTagCrawlPage(this.token, this.crawlpage.id, [tag.id])
      .then(() => {
        const index = this.crawlpage.tags.indexOf(tag);
        if (index > -1) {
          this.crawlpage.tags.splice(index, 1);
        }
      })
      .catch((error) => {
        this.$refs.globalAPILoadingErrorModal.show()
        throw new Error('crawlpageAddTagsComponent tagCrawlPage API Error : ' + String(error))
      })
      .finally(() => {
        this.isTaggingCrawlpage = false
      })
    }

  }
}
</script>
