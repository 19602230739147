<template>
  <div class="formTitleComponent">
    <div class="decidcrawl-background form_div_pre_title"> </div>
  </div>
</template>

<script>
export default {
  name: 'formTitleComponent',
}
</script>
<style>
.decidcrawl-background {
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background: #F9BF3B;
}
.form_div_pre_title {
  height: 120px;
}
</style>
