<template>
  <div class="pageProfileComponent">

      <!-- ERROR MODAL -->
      <b-modal ref="globalAPILoadingErrorModal" id="globalAPILoadingErrorModal"  hide-footer title="Une erreur s'est produite">
        <div class="text-center">
          <p>Une erreur s'est produite, veuillez actualiser la page et réessayer</p>
          <b-button class="mt-3" variant="outline-dark" @click="$bvModal.hide('globalAPILoadingErrorModal')">Fermer</b-button>
        </div>
      </b-modal>

      <!-- ALL CRAWLED PAGE AS READ -->
      <b-modal ref="allCrawlPageMarkAsRead" id="allCrawlPageMarkAsRea"  hide-footer title="Toutes vos pages ont été marquées comme lues">
        <div class="text-center">
          <p>Vous avez bien marqué toutes vos pages comme lues</p>
          <b-button class="mt-3" variant="outline-dark" @click="$bvModal.hide('allCrawlPageMarkAsRea')">Fermer</b-button>
        </div>
      </b-modal>

      <!-- CRAWLED PAGE AS READ -->
      <b-modal ref="crawlPageMarkAsRead" id="crawlPageMarkAsRead"  hide-footer title="Votre page a bien été marquée comme lue">
        <div class="text-center">
          <p>Votre page crawlée a bien été marquée comme lue</p>
          <b-button class="mt-3" variant="outline-dark" @click="$bvModal.hide('crawlPageMarkAsRead')">Fermer</b-button>
        </div>
      </b-modal>

      <!-- ALL CRAWLED PAGE AS READ -->
      <b-modal ref="pageCrawled" id="pageCrawled"  hide-footer title="Votre crawling a bien été lancé">
        <div class="text-center">
          <p>Votre crawling a bien été lancé et ajouté à la liste des crawl, veuillez revenir un peu plus tard :)</p>
          <b-button class="mt-3" variant="outline-dark" @click="$bvModal.hide('pageCrawled')">Fermer</b-button>
        </div>
      </b-modal>


      <loading
        :active.sync="isLoading"
        :can-cancel="false"
        :is-full-page="true"
        color="#F9BF3B">
      </loading>


      <div class="row">
        <div class="col-sm-8 col-12 align-self-center">
          <h2>
            <b-button
              @click="$emit('back-to-folder', {'parent': true, 'id': page.folder})"
              size="sm"
              variant="outline-secondary"
            >
              <v-icon name="arrow-left"/>
            </b-button>

            <strong> PAGE </strong> - {{page.name}} / <strong><a :href="page.url" target="_blank">{{page.url}}</a></strong>
            <b-badge v-if="page.is_bugged" pill variant="danger">Bug</b-badge>
            <b-badge class="ml-2" v-if="page.last_status" pill variant="info">{{page.last_status}}</b-badge>
            <b-badge class="ml-2" v-if="page.crawling_time > 0" pill variant="primary">{{minutesAndSeconds(page.crawling_time)}}</b-badge>
          </h2>
        </div>
        <div class="col-sm-4 col-12 text-right align-self-center">
          <b-button 
            @click="crawlPage()" 
            variant="outline-success" 
            pill
            :disabled="pageNotCrawlable"
          >
            Crawler cette page
          </b-button>
        </div>
      </div>
      <p
        v-if="!page.folder_requete"
        class="text-danger"
      >
        Cette page n'est pas crawlable le dossier parent n'a pas de requête associée
      </p> 
      <p v-if="page.is_bugged">
        <strong> Message de bug : </strong> {{page.bug_message}}
      </p>
      <p>
        <modifyPageNotesComponent
          :pageId="page.id"
          :token="token"
          :notes="page.notes"
        />
      </p>

      <p>
        Pages crawlées détectées et non luees : <b-badge pill variant="primary">{{pageStats.nb_unread_detected}}</b-badge><br>
        Pages crawlées détectées et luées : <b-badge pill variant="primary">{{pageStats.nb_read_detected}}</b-badge><br>
        Pages crawlées non détectées <b-badge pill variant="primary">{{pageStats.nb_undetected}}</b-badge>
      </p>

      <div class="row">
        <div class="col-sm-6">
          <b-form-select
            size="sm"
            v-model="crawlPagesType"
            id="crawlpages-type-input"
            :options='crawlPagesTypeOptions'>
          </b-form-select>
        </div>
        <div v-if="isCrawlPagesDetectedUnread" class="col-sm-6 col-12 text-right">
          <b-button @click="markAllCrawlPagesAsReadFromPage()" variant="outline-info" pill size="sm">Marquer toutes ces pages comme lues</b-button>
        </div>
      </div>


      <div class="row mt-2">
        <div class="col-sm-6">
          <b-form-group id="start-date-group" label="Date de début" label-for="start-date-input">
            <b-form-datepicker
              id="start-date-input"
              name="start-date-input"
              locale="fr"
              size="sm"
              :max="today"
              @input="getAllChildrenCrawlPages"
              :show-decade-nav="true"
              placeholder="Choisissez une date"
              v-model="startDate" class="mb-1">
            </b-form-datepicker>
          </b-form-group>
        </div>
        <div class="col-sm-6">
          <b-form-group id="end-date-group" label="Date de fin" label-for="end-date-input">
            <b-form-datepicker
              id="end-date-input"
              name="end-date-input"
              locale="fr"
              size="sm"
              :max="today"
              @input="getAllChildrenCrawlPages"
              :show-decade-nav="true"
              placeholder="Choisissez une date"
              v-model="endDate" class="mb-1">
            </b-form-datepicker>
          </b-form-group>
        </div>
      </div>


      <div class="mt-2" v-for="crawlpage in crawlPages" v-bind:key="crawlpage.id">
        <b-card class="mt-2">
          <b-card-title>
            {{crawlpage.title}}
            <b-badge 
              v-if="crawlpage.keywords"
              pill 
              variant="info"
              v-b-tooltip.hover 
              :title="crawlpage.keywords"
            >
              <v-icon name="info"/>
            </b-badge>
            <b-badge 
              v-if="crawlpage.exclusion_keywords"
              pill 
              variant="danger"
              v-b-tooltip.hover 
              :title="crawlpage.exclusion_keywords"
            >
              <v-icon name="info"/>
            </b-badge>
          </b-card-title>
          <b-card-sub-title><em>{{page.name}}</em> / {{$dayjs(crawlpage.created_at).format('DD/MM/YYYY - HH:mm')}}</b-card-sub-title>
          <b-card-text>
            <div class="row">
              <div class="col-12">
                <a :href="crawlpage.url" target="_blank"><small>{{crawlpage.url}}</small></a><br>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-sm-8 align-self-center">
                <crawlpageAddTagsComponent
                  :tags="tags"
                  :token="token"
                  :crawlpage="crawlpage"
                />
              </div>
              <div v-if="crawlPagesType == 'DETEC-UNREAD'" class="col-sm-4 col-12 text-right align-self-center">
                <b-button @click="markCrawlPageAsRead(crawlpage)" size="sm" variant="outline-success" pill>Marquer comme lu</b-button>
              </div>
            </div>

          </b-card-text>
        </b-card>
      </div>
  </div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'


import { APIPage } from '@/api/APIPage'
import { APICrawlPage } from '@/api/APICrawlPage'
import crawlpageAddTagsComponent from '@/components/crawlpage/crawlpageAddTagsComponent'
import modifyPageNotesComponent from '@/components/page/modifyPageNotesComponent'
import { isDate } from '@/validators/validators'
import renderMixins from '@/mixins/renderMixins'
const apiCrawlPage = new APICrawlPage()
const apiPage = new APIPage()


export default {
  name: 'pageProfileComponent',
  props: {
    pageId: Number,
    token: String,
    tags: Array
  },
  components: {
    Loading,
    crawlpageAddTagsComponent,
    modifyPageNotesComponent
  },
  mixins: [
    renderMixins,
    validationMixin
  ],
  data() {
    const today = this.$dayjs()
    const two_days_ago = today.subtract(2, 'days')
     return {
       isLoadingPage: false,
       page: {
       },
       pageStats: {
         nb_unread_detected: null,
         nb_read_detected: null,
         nb_undetected: null
       },

       isCrawlPagesDetectedUnread: true,
       isCrawlPagesDetectedRead: false,
       isCrawlPagesUnDetected: false,

       isCrawlPagesLoading: false,
       crawlPagesType: 'DETEC-UNREAD',
       crawlPagesTypeOptions: [
         { text: 'Non lues et répondant à la requête', value: 'DETEC-UNREAD' },
         { text: 'Lues et répondant à la requête', value:  'DETEC-READ' },
         { text: 'Ne répondant pas à la requête', value:  'UNDETEC' },
       ],

       startDate: two_days_ago.format('YYYY-MM-DD'),
       endDate: today.format("YYYY-MM-DD"),
       today: today.format('YYYY-MM-DD'),
       crawlPages: [],

       isMarkingCrawlPageAsRead: false,
     }
  },

  computed: {
    isLoading () {
      if (this.isLoadingPage || this.isCrawlPagesLoading ) {
        return true
      }
      return false
    },
    pageNotCrawlable () {
      if (!this.page.folder_requete) {
        return true
      }
      return false
    }
  },
  validations: {
    startDate: {
      required,
      validDate(value) {
        return isDate(value)
      }
    },
    endDate: {
      required,
      validDate(value) {
        return isDate(value)
      }
    }
  },

  mounted: function() {
    this.getPage()
  },

  watch: {
    pageId: function() {
      this.getPage()
    },
    crawlPagesType: function() {
      this.getAllChildrenCrawlPages()
    },
  },

  methods: {
    crawlPage () {
      apiPage.crawlPage(this.token, this.pageId)
      .then(() => {
        this.$refs.pageCrawled.show()
      })
      .catch((error) => {
        throw new Error('PageMovingComponent crawlPage API Error : ' + String(error))
      })
    },

    getPage() {
      this.isLoadingPage = true
      apiPage.getPage(this.token, this.pageId)
      .then((result) => {
        this.page = result.data
        this.getAllChildrenCrawlPagesDetectedUnread()
        this.getPageStatistics()
      })
      .catch((error) => {
        this.$refs.globalAPILoadingErrorModal.show()
        throw new Error('pageProfileComponent getPage API Error : ' + String(error))
      })
      .finally(() => {
        this.isLoadingPage = false
      })
    },

    getPageStatistics() {
      apiPage.getPageStatistics(this.token, this.pageId, this.startDate, this.endDate)
      .then((result) => {
        this.pageStats = result.data
      })
      .catch((error) => {
        this.$refs.globalAPILoadingErrorModal.show()
        throw new Error('pageProfileComponent getPageStatistics API Error : ' + String(error))
      })
    },

    getAllChildrenCrawlPages() {
      if (this.crawlPagesType == 'DETEC-UNREAD') {
        this.getAllChildrenCrawlPagesDetectedUnread()
      }
      else if (this.crawlPagesType == 'DETEC-READ') {
        this.getAllChildrenCrawlPagesDetectedRead()
      }
      else if (this.crawlPagesType == 'UNDETEC') {
        this.getAllChildrenCrawlPagesUndetected()
      }
      this.getPageStatistics()
    },

    getAllChildrenCrawlPagesDetectedUnread () {
      this.isCrawlPagesLoading = true
      apiCrawlPage.getAllChildrenCrawlPagesDetectedUnread(this.token, this.pageId, this.startDate, this.endDate)
      .then((result) => {
        this.crawlPages = result.data
        this.isCrawlPagesDetectedUnread = true
        this.isCrawlPagesDetectedRead = false
        this.isCrawlPagesUnDetected = false
      })
      .catch((error) => {
        this.$refs.globalAPILoadingErrorModal.show()
        throw new Error('pageProfileComponent getAllChildrenCrawlPagesDetectedUnread API Error : ' + String(error))
      })
      .finally(() => {
        this.isCrawlPagesLoading = false
      })
    },

    getAllChildrenCrawlPagesDetectedRead () {
      this.isCrawlPagesLoading = true
      apiCrawlPage.getAllChildrenCrawlPagesDetectedRead(this.token, this.pageId, this.startDate, this.endDate)
      .then((result) => {
        this.crawlPages = result.data
        this.isCrawlPagesDetectedUnread = false
        this.isCrawlPagesDetectedRead = true
        this.isCrawlPagesUnDetected = false
      })
      .catch((error) => {
        this.$refs.globalAPILoadingErrorModal.show()
        throw new Error('pageProfileComponent getAllChildrenCrawlPagesDetectedRead API Error : ' + String(error))
      })
      .finally(() => {
        this.isCrawlPagesLoading = false
      })
    },

    getAllChildrenCrawlPagesUndetected () {
      this.isCrawlPagesLoading = true
      apiCrawlPage.getAllChildrenCrawlPagesUndetected(this.token, this.pageId, this.startDate, this.endDate)
      .then((result) => {
        this.crawlPages = result.data
        this.isCrawlPagesDetectedUnread = false
        this.isCrawlPagesDetectedRead = false
        this.isCrawlPagesUnDetected = true
      })
      .catch((error) => {
        this.$refs.globalAPILoadingErrorModal.show()
        throw new Error('pageProfileComponent getAllChildrenCrawlPagesUndetected API Error : ' + String(error))
      })
      .finally(() => {
        this.isCrawlPagesLoading = false
      })
    },


    markAllCrawlPagesAsReadFromPage () {
      this.isMarkingCrawlPageAsRead = true
      apiCrawlPage.markAllCrawlPagesAsReadFromPage(this.token, this.pageId, this.startDate, this.endDate)
      .then(() => {
        this.$refs.allCrawlPageMarkAsRead.show()
        this.page.nb_crawledpage_detected = 0
        this.crawlPages = []
      })
      .catch((error) => {
        this.$refs.globalAPILoadingErrorModal.show()
        throw new Error('pageProfileComponent markAllCrawlPagesAsReadFromPage API Error : ' + String(error))
      })
      .finally(() => {
        this.isMarkingCrawlPageAsRead = false
      })
    },

    markCrawlPageAsRead (crawlpage) {
      this.isMarkingCrawlPageAsRead = true
      apiCrawlPage.markCrawlPageAsRead(this.token, crawlpage.id)
      .then(() => {
        this.$refs.crawlPageMarkAsRead.show()
        this.page.nb_crawledpage_detected --
        const index = this.crawlPages.indexOf(crawlpage);
        if (index > -1) {
          this.crawlPages.splice(index, 1);
        }
      })
      .catch((error) => {
        this.$refs.globalAPILoadingErrorModal.show()
        throw new Error('pageProfileComponent markCrawlPageAsRead API Error : ' + String(error))
      })
      .finally(() => {
        this.isMarkingCrawlPageAsRead = false
      })
    },

  }
}
</script>
<style>
.card-title {
  font-size: 1em;
  font-weight: 600;
}
</style>
