<template>
  <div class="folderProfileComponent">

      <!-- ERROR MODAL -->
      <b-modal ref="globalAPILoadingErrorModal" id="globalAPILoadingErrorModal"  hide-footer title="Une erreur s'est produite">
        <div class="text-center">
          <p>Une erreur s'est produite, veuillez actualiser la page et réessayer</p>
          <b-button class="mt-3" variant="outline-dark" @click="$bvModal.hide('globalAPILoadingErrorModal')">Fermer</b-button>
        </div>
      </b-modal>

      <!-- ALL CRAWLED PAGE AS READ -->
      <b-modal ref="allCrawlPageMarkAsRead" id="allCrawlPageMarkAsRea"  hide-footer title="Toutes vos pages ont été marquées comme lues">
        <div class="text-center">
          <p>Vous avez bien marqué toutes vos pages comme lues</p>
          <b-button class="mt-3" variant="outline-dark" @click="$bvModal.hide('allCrawlPageMarkAsRea')">Fermer</b-button>
        </div>
      </b-modal>

      <!-- CRAWLED PAGE AS READ -->
      <b-modal ref="crawlFolderMarkAsRead" id="crawlFolderMarkAsRead"  hide-footer title="Votre page a bien été marquée comme lue">
        <div class="text-center">
          <p>Votre page crawlée a bien été marquée comme lue</p>
          <b-button class="mt-3" variant="outline-dark" @click="$bvModal.hide('crawlFolderMarkAsRead')">Fermer</b-button>
        </div>
      </b-modal>

      <!-- ALL CRAWLED PAGE AS READ -->
      <b-modal ref="folderCrawled" id="folderCrawled"  hide-footer title="Votre crawling a bien été lancé">
        <div class="text-center">
          <p>Votre crawling a bien été lancé et ajouté à la liste des crawl, veuillez revenir un peu plus tard :)</p>
          <b-button class="mt-3" variant="outline-dark" @click="$bvModal.hide('folderCrawled')">Fermer</b-button>
        </div>
      </b-modal>


      <loading
        :active.sync="isLoading"
        :can-cancel="false"
        :is-full-page="true"
        color="#F9BF3B">
      </loading>


      <div class="row">
        <div class="col-sm-8 col-12 align-self-center">
          <h2>
            <strong> DOSSIER </strong> - {{folder.name}}
            <b-badge class="ml-2" v-if="folder.crawling_time > 0" pill variant="primary">{{minutesAndSeconds(folder.crawling_time)}}</b-badge>
          </h2>
        </div>
        <div class="col-sm-4 col-12 text-right align-self-center">
          <b-button @click="crawlFolder()" variant="outline-success" pill>Crawler ce dossier</b-button>
        </div>
      </div>

      <p>
        <modifyFolderNotesComponent
          :folderId="folder.id"
          :token="token"
          :notes="folder.notes"
        />
      </p>

      <hr>

      <p>
        <strong> Statistiques sur la période choisie </strong> : <br>
        Pages crawlées détectées et non luees : <b-badge pill variant="primary">{{folderStats.nb_unread_detected}}</b-badge><br>
        Pages crawlées détectées et luées : <b-badge pill variant="primary">{{folderStats.nb_read_detected}}</b-badge><br>
        Pages crawlées non détectées <b-badge pill variant="primary">{{folderStats.nb_undetected}}</b-badge>
      </p>

      <p>
        <b-button v-b-toggle.folder-pages-without-crawlpages-collapse class="m-1" variant="link" style="color: black; padding: 0px;">
          <strong>Analyse des pages sans résultats</strong>

          <small> (Cliquez pour masquer)</small>
        </b-button>
        <b-collapse visible id="folder-pages-without-crawlpages-collapse">
          <div class="row mt-2">
            <div class="col-sm-6">
              <b-form-group id="start-date-group" label="Date de début" label-for="start-date-input">
                <b-form-datepicker
                  id="pagesWithoutCrawlpagesStartDate-date-input"
                  name="pagesWithoutCrawlpagesStartDate-date-input"
                  locale="fr"
                  size="sm"
                  :max="today"
                  @input="getFolderPagesWithoutCrawlpages"
                  :show-decade-nav="true"
                  placeholder="Choisissez une date"
                  v-model="pagesWithoutCrawlpagesStartDate" class="mb-1">
                </b-form-datepicker>
              </b-form-group>
            </div>
            <div class="col-sm-6">
              <b-form-group id="end-date-group" label="Date de fin" label-for="end-date-input">
                <b-form-datepicker
                  id="pagesWithoutCrawlpagesEndDate-date-input"
                  name="pagesWithoutCrawlpagesEndDate-date-input"
                  locale="fr"
                  size="sm"
                  :max="today"
                  @input="getFolderPagesWithoutCrawlpages"
                  :show-decade-nav="true"
                  placeholder="Choisissez une date"
                  v-model="pagesWithoutCrawlpagesEndDate" class="mb-1">
                </b-form-datepicker>
              </b-form-group>
            </div>
          </div>

          <span
            v-if="pagesWithoutCrawlpages === null"
          >
            Chargement en cours... <b-spinner small type="grow" variant="primary"></b-spinner>
          </span>
          <span
            v-else-if="pagesWithoutCrawlpages.length == 0"
          >
            Aucune page sans résultats dans la période choisie
          </span>
          <span
            v-else
          >
            {{pagesWithoutCrawlpages.length}} pages sans résultats dans la période choisie
            <br>
            <b-button 
              v-for="page in pagesWithoutCrawlpages"
              v-bind:key="page.id"
              @click="$emit('choose-page', page)"
              variant="link"
            >
              {{page.name}} // 
            </b-button> 
          </span>
        </b-collapse>

        
      </p>

      <div class="row">
        <div class="col-sm-6">
          <b-form-select
            size="sm"
            v-model="crawlPagesType"
            id="crawlpages-type-input"
            :options='crawlPagesTypeOptions'>
          </b-form-select>
        </div>
        <div v-if="isCrawlPagesDetectedUnread" class="col-sm-6 col-12 text-right">
          <b-button @click="markAllCrawlPagesAsReadFromFolder()" variant="outline-info" pill size="sm">Marquer toutes ces pages comme lues</b-button>
        </div>
      </div>


      <div class="row mt-2">
        <div class="col-sm-6">
          <b-form-group id="start-date-group" label="Date de début" label-for="start-date-input">
            <b-form-datepicker
              id="start-date-input"
              name="start-date-input"
              locale="fr"
              size="sm"
              :max="today"
              @input="getAllCrawlPagesFromFolder"
              :show-decade-nav="true"
              placeholder="Choisissez une date"
              v-model="startDate" class="mb-1">
            </b-form-datepicker>
          </b-form-group>
        </div>
        <div class="col-sm-6">
          <b-form-group id="end-date-group" label="Date de fin" label-for="end-date-input">
            <b-form-datepicker
              id="end-date-input"
              name="end-date-input"
              locale="fr"
              size="sm"
              :max="today"
              @input="getAllCrawlPagesFromFolder"
              :show-decade-nav="true"
              placeholder="Choisissez une date"
              v-model="endDate" class="mb-1">
            </b-form-datepicker>
          </b-form-group>
        </div>
      </div>


      <div class="mt-2" v-for="crawlpage in crawlpages" v-bind:key="crawlpage.id">
        <b-card class="mt-2">
          <b-card-title>
            {{crawlpage.title}}
            <b-badge 
              v-if="crawlpage.keywords"
              pill 
              variant="info"
              v-b-tooltip.hover 
              :title="crawlpage.keywords"
            >
              <v-icon name="info"/>
            </b-badge>
            <b-badge 
              v-if="crawlpage.exclusion_keywords"
              pill 
              variant="danger"
              v-b-tooltip.hover 
              :title="crawlpage.exclusion_keywords"
            >
              <v-icon name="info"/>
            </b-badge>
          </b-card-title>
          <b-card-sub-title><em>{{crawlpage.page_name}}</em> / {{$dayjs(crawlpage.created_at).format('DD/MM/YYYY - HH:mm')}}</b-card-sub-title>
          <b-card-text>
            <div class="row">
              <div class="col-12">
                <a :href="crawlpage.url" target="_blank"><small>{{crawlpage.url}}</small></a><br>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-sm-8 align-self-center">
                <crawlpageAddTagsComponent
                  :tags="tags"
                  :token="token"
                  :crawlpage="crawlpage"
                />
              </div>
              <div v-if="crawlPagesType == 'DETEC-UNREAD'" class="col-sm-4 col-12 text-right align-self-center">
                <b-button @click="markCrawlPageAsRead(crawlpage)" size="sm" variant="outline-success" pill>Marquer comme lu</b-button>
              </div>
            </div>

          </b-card-text>
        </b-card>
      </div>
  </div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'


import { APIFolder } from '@/api/APIFolder'
import { APICrawlPage } from '@/api/APICrawlPage'
import crawlpageAddTagsComponent from '@/components/crawlpage/crawlpageAddTagsComponent'
import modifyFolderNotesComponent from '@/components/folder/modifyFolderNotesComponent'
import { isDate } from '@/validators/validators'
import renderMixins from '@/mixins/renderMixins'

const apiCrawlPage = new APICrawlPage()
const apiFolder = new APIFolder()


export default {
  name: 'folderProfileComponent',
  props: {
    folderId: Number,
    token: String,
    tags: Array
  },
  components: {
    Loading,
    crawlpageAddTagsComponent,
    modifyFolderNotesComponent
  },
  mixins: [
    renderMixins,
    validationMixin
  ],
  data() {
    const today = this.$dayjs()
    const two_days_ago = today.subtract(2, 'days')
      return {
        isLoadingFolder: false,
        folder: {
          crawling_time: 0
        },
        folderStats: {
          nb_unread_detected: null,
          nb_read_detected: null,
          nb_undetected: null
        },
        isCrawlPagesDetectedUnread: true,
        isCrawlPagesDetectedRead: false,
        isCrawlPagesUnDetected: false,

        isCrawlPagesLoading: false,
        crawlPagesType: 'DETEC-UNREAD',
        crawlPagesTypeOptions: [
          { text: 'Non lues et répondant à la requête', value: 'DETEC-UNREAD' },
          { text: 'Lues et répondant à la requête', value:  'DETEC-READ' },
          { text: 'Ne répondant pas à la requête', value:  'UNDETEC' },
        ],

        pagesWithoutCrawlpages: null,

        startDate: two_days_ago.format('YYYY-MM-DD'),
        endDate: today.format("YYYY-MM-DD"),
        today: today.format('YYYY-MM-DD'),
        crawlpages: [],

        isMarkingCrawlPageAsRead: false,
     }
  },

  computed: {
    isLoading () {
      if (this.isLoadingFolder || this.isCrawlPagesLoading ) {
        return true
      }
      return false
    },
    pagesWithoutCrawlpagesStartDate: {
      get () {
        return this.$store.state.pagesWithoutCrawlpagesStartDate
      },
      set (value) {
        this.$store.commit('set', ['pagesWithoutCrawlpagesStartDate', value])
      }
    },
    pagesWithoutCrawlpagesEndDate: {
      get () {
        return this.$store.state.pagesWithoutCrawlpagesEndDate
      },
      set (value) {
        this.$store.commit('set', ['pagesWithoutCrawlpagesEndDate', value])
      }
    },
  },
  validations: {
    startDate: {
      required,
      validDate(value) {
        return isDate(value)
      }
    },
    endDate: {
      required,
      validDate(value) {
        return isDate(value)
      }
    }
  },

  mounted: function() {
    const today = this.$dayjs()
    const fifteen_days_ago = today.subtract(15, 'days')
    if (!this.pagesWithoutCrawlpagesStartDate) {
      this.pagesWithoutCrawlpagesStartDate = fifteen_days_ago.format('YYYY-MM-DD')
    }
    if (!this.pagesWithoutCrawlpagesEndDate) {
      this.pagesWithoutCrawlpagesEndDate = today.format('YYYY-MM-DD')
    }
    this.getFolder()
  },

  watch: {
    folderId: function() {
      this.getFolder()
    },
    crawlPagesType: function() {
      this.getAllCrawlPagesFromFolder()
    },
  },

  methods: {
    crawlFolder () {
      apiFolder.crawlFolder(this.token, this.folderId)
      .then(() => {
        this.$refs.folderCrawled.show()
      })
      .catch((error) => {
        throw new Error('PageMovingComponent crawlFolder API Error : ' + String(error))
      })
    },

    getFolder() {
      this.isLoadingFolder = true
      apiFolder.getFolder(this.token, this.folderId)
      .then((result) => {
        this.folder = result.data
        this.getAllCrawlPagesFromFolder()
        this.getFolderCrawlpageStatistics()
        this.getFolderPagesWithoutCrawlpages()
      })
      .catch((error) => {
        this.$refs.globalAPILoadingErrorModal.show()
        throw new Error('folderProfileComponent getFolder API Error : ' + String(error))
      })
      .finally(() => {
        this.isLoadingFolder = false
      })
    },

    getFolderCrawlpageStatistics() {
      apiFolder.getFolderCrawlpageStatistics(this.token, this.folderId, this.startDate, this.endDate)
      .then((result) => {
        this.folderStats = result.data
      })
      .catch((error) => {
        this.$refs.globalAPILoadingErrorModal.show()
        throw new Error('folderProfileComponent getFolderCrawlpageStatistics API Error : ' + String(error))
      })
    },

    getFolderPagesWithoutCrawlpages () {
      this.pagesWithoutCrawlpages = null
      apiFolder.getFolderPagesWithoutCrawlpages(this.token, this.folderId, this.pagesWithoutCrawlpagesStartDate, this.pagesWithoutCrawlpagesEndDate)
      .then((result) => {
        this.pagesWithoutCrawlpages = result.data
      })
      .catch((error) => {
        this.$refs.globalAPILoadingErrorModal.show()
        throw new Error('folderProfileComponent getFolderPagesWithoutCrawlpages API Error : ' + String(error))
      })
    },

    getAllCrawlPagesFromFolder() {
      if (this.crawlPagesType == 'DETEC-UNREAD') {
        this.getAllFolderCrawlPagesDetectedUnread()
      }
      else if (this.crawlPagesType == 'DETEC-READ') {
        this.getAllFolderCrawlPagesDetectedRead()
      }
      else if (this.crawlPagesType == 'UNDETEC') {
        this.getAllFolderCrawlPagesUndetected()
      }
      this.getFolderCrawlpageStatistics()
    },

    getAllFolderCrawlPagesDetectedUnread () {
      this.isCrawlPagesLoading = true
      apiCrawlPage.getAllCrawlPagesFromFolder(this.token, this.folderId, this.startDate, this.endDate, true, false)
      .then((result) => {
        this.crawlpages = result.data
        this.isCrawlPagesDetectedUnread = true
        this.isCrawlPagesDetectedRead = false
        this.isCrawlPagesUnDetected = false
      })
      .catch((error) => {
        this.$refs.globalAPILoadingErrorModal.show()
        throw new Error('folderProfileComponent getAllFolderCrawlPagesDetectedUnread API Error : ' + String(error))
      })
      .finally(() => {
        this.isCrawlPagesLoading = false
      })
    },

    getAllFolderCrawlPagesDetectedRead () {
      this.isCrawlPagesLoading = true
      apiCrawlPage.getAllCrawlPagesFromFolder(this.token, this.folderId, this.startDate, this.endDate, true, true)
      .then((result) => {
        this.crawlpages = result.data
        this.isCrawlPagesDetectedUnread = false
        this.isCrawlPagesDetectedRead = true
        this.isCrawlPagesUnDetected = false
      })
      .catch((error) => {
        this.$refs.globalAPILoadingErrorModal.show()
        throw new Error('folderProfileComponent getAllFolderCrawlPagesDetectedRead API Error : ' + String(error))
      })
      .finally(() => {
        this.isCrawlPagesLoading = false
      })
    },

    getAllFolderCrawlPagesUndetected () {
      this.isCrawlPagesLoading = true
      apiCrawlPage.getAllCrawlPagesFromFolder(this.token, this.folderId, this.startDate, this.endDate, false, null)
      .then((result) => {
        this.crawlpages = result.data
        this.isCrawlPagesDetectedUnread = false
        this.isCrawlPagesDetectedRead = false
        this.isCrawlPagesUnDetected = true
      })
      .catch((error) => {
        this.$refs.globalAPILoadingErrorModal.show()
        throw new Error('folderProfileComponent getAllFolderCrawlPagesUndetected API Error : ' + String(error))
      })
      .finally(() => {
        this.isCrawlPagesLoading = false
      })
    },


    markAllCrawlPagesAsReadFromFolder () {
      this.isMarkingCrawlPageAsRead = true
      apiCrawlPage.markAllCrawlPagesAsReadFromFolder(this.token, this.folderId, this.startDate, this.endDate)
      .then(() => {
        this.$refs.allCrawlPageMarkAsRead.show()
        this.crawlpages = []
      })
      .catch((error) => {
        this.$refs.globalAPILoadingErrorModal.show()
        throw new Error('folderProfileComponent markAllCrawlPagesAsReadFromFolder API Error : ' + String(error))
      })
      .finally(() => {
        this.isMarkingCrawlPageAsRead = false
      })
    },

    markCrawlPageAsRead (crawlpage) {
      this.isMarkingCrawlPageAsRead = true
      apiCrawlPage.markCrawlPageAsRead(this.token, crawlpage.id)
      .then(() => {
        this.$refs.crawlFolderMarkAsRead.show()
        const index = this.crawlpages.indexOf(crawlpage);
        if (index > -1) {
          this.crawlpages.splice(index, 1);
        }
      })
      .catch((error) => {
        this.$refs.globalAPILoadingErrorModal.show()
        throw new Error('folderProfileComponent markCrawlPageAsRead API Error : ' + String(error))
      })
      .finally(() => {
        this.isMarkingCrawlPageAsRead = false
      })
    },

  }
}
</script>
<style>
.card-title {
  font-size: 1em;
  font-weight: 600;
}
</style>
