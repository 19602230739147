import Vue from 'vue'
import Vuex from 'vuex'
import user from './modules/user'
import auth from './modules/auth'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

const state = {
  // ---------- FILTER PAGE WITHOUT CRAWLPAGES PERIOD -------------
  pagesWithoutCrawlpagesStartDate: null,
  pagesWithoutCrawlpagesEndDate: null,
}

const mutations = {
  set (state, [variable, value]) {
    state[variable] = value
  },
}

export default new Vuex.Store({
  modules: {
    user,
    auth,
  },
  strict: debug,
  state,
  mutations
})
